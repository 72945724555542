
import { defineComponent } from 'vue';
import { SelectOption } from '@/interfaces/ui';
import { uniqueId } from '@/helpers';

export default defineComponent({
    name: 'SimpleSelect',
    emits: ['update:modelValue'],
    data: () => ({
        id: '',
        expanded: false,
    }),
    props: {
        modelValue: {
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: (): string[] => [],
        },
    },
    computed: {
        typedOptions(): SelectOption[] {
            return this.options as SelectOption[];
        },
        valueLabel(): string {
            const selectedItem = this.typedOptions.find(({ value }) => value === this.modelValue);
            if (selectedItem) {
                return selectedItem.label;
            }
            return '';
        },
        classes(): Record<string, boolean> {
            return {
                'simple-select--expanded': this.expanded,
                'simple-select--disabled': this.disabled,
                'simple-select--readonly': this.readonly,
                'simple-select--error': this.errors.length > 0,
            };
        },
    },
    methods: {
        emitInput(value: string | null): void {
            this.$emit('update:modelValue', value);
            this.expanded = false;
        },
    },
    created() {
        this.id = uniqueId();
    },
});
