
import { defineComponent } from 'vue';
import { ShipShopSpot, ShipShopSpotConfig, ShipShopSpotConfigSize } from '@/interfaces/ingame/shipShopSpot';

export default defineComponent({
    name: 'ShipShopLayoutSpotPattern',
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    computed: {
        typedConfig(): ShipShopSpotConfig {
            return this.config as ShipShopSpotConfig;
        },
        configSize(): ShipShopSpotConfigSize {
            if (this.typedConfig.spot) {
                const spot = this.typedConfig.spot as ShipShopSpot;
                const size = this.typedConfig.sizes.find((fsize) => fsize.size === spot.size);
                if (size) return size;
            }
            return this.typedConfig.sizes[0];
        },
    },
});
