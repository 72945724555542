
import { defineComponent } from 'vue';
import { ShipShopSpotConfig } from '@/interfaces/ingame/shipShopSpot';
import ShipShopLayoutSpot from '@/components/ingame/shipShop/ShipShopLayoutSpot.vue';
import ShipShopLayoutSpotPattern from '@/components/ingame/shipShop/ShipShopLayoutSpotPattern.vue';

// <rect id="spot-([0-9]+)" class="ship-shop-layout__spot" x="([0-9\.]+)" y="([0-9\.]+)" width="([0-9\.]+)" height="([0-9\.]+)"[ ]*/>
// <g id="spot-$1" transform="translate($2, $3)"><rect class="ship-shop-layout__spot" width="$4" height="$5" /><text class="ship-shop-layout__spot-number" x="$4" y="$5">$1</text></g>
// {"id": "spot-$1", "class": "ship-shop-layout__spot", "x": $2, "y": $3, "sizes": [{ "size": 1, "width": $4, "height": $5 }] },
export default defineComponent({
    name: 'ShipShopLayoutLarge',
    emits: ['spotClicked'],
    components: {
        ShipShopLayoutSpot,
        ShipShopLayoutSpotPattern,
    },
    props: {
        shipShopSpotConfigs: {
            type: Array,
            required: true,
        },
    },
    computed: {
        typedShipShopSpotConfigs(): ShipShopSpotConfig[] {
            return this.shipShopSpotConfigs as ShipShopSpotConfig[];
        },
        hiddenPositions(): number[] {
            const hidden: number[] = [];
            this.typedShipShopSpotConfigs.forEach(({ spot, sizes }) => {
                if (spot) {
                    let size = sizes.find((fsize) => fsize.size === spot.size);
                    if (!size) [size] = sizes;

                    if (size.hides && size.hides.length > 0) {
                        hidden.push(...size.hides);
                    }
                }
            });
            return [...new Set(hidden)];
        },
    },
    methods: {
        handleSpotClicked(position: number): void {
            this.$emit('spotClicked', position);
        },
    },
});
